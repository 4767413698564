<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="(isNew) ? title : item.name" back="Convention.Assistant.Logins" :readonly="$authorised('con/assistant/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid || model.used" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item label="Status" :status="item.status" :tag="$constants.assistant.login.statuses[item.status]" />
			<app-content-summary-item label="Name" :grow="true" :value="item.name" />
			<app-content-summary-item label="Type" :value="$constants.assistant.login.types[item.type]" />
			<app-content-summary-item label="E-mail" :value="item.user.email" v-if="isAccount" />
			<app-content-summary-item label="Code" :value="item.code" v-if="!isAccount" />

		</app-content-summary>

		<app-content-box v-if="!isNew" :readonly="$authorised('con/assistant/access', 'read')">

			<app-input-toggle label="Status" v-model="model.status" :validation="$v.model.status" :options="$constants.assistant.login.statuses" />
			<app-input-text v-if="!isAccount" label="Name" placeholder="Enter name" v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist label="Scope" v-model="model.scope" :validation="$v.model.scope" :options="$constants.assistant.scopes" />

		</app-content-box>

		<app-content-box v-if="isNew" :readonly="$authorised('con/assistant/access', 'read')">

			<app-input-toggle label="Status" v-model="model.status" :validation="$v.model.status" :options="$constants.assistant.login.statuses" />
			<app-input-toggle label="Type" v-model="model.type" :validation="$v.model.type" :options="$constants.assistant.login.types" />
			<app-input-account v-if="isAccount" label="E-mail" type="assistant" :text="emailMessages" v-model="model.email" v-on:exists="onExistsChange" :validation="$v.model.email" />
			<app-input-text v-if="isAccount" :disabled="$v.model.email.$invalid || model.exists" label="Firstname" placeholder="Enter firstname" v-model="model.firstname" :validation="$v.model.firstname" />
			<app-input-text v-if="isAccount" :disabled="$v.model.email.$invalid || model.exists" label="Lastname" placeholder="Enter lastname" v-model="model.lastname" :validation="$v.model.lastname" />
			<app-input-text v-if="!isAccount" label="Name" placeholder="Enter name" v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist label="Scope" v-model="model.scope" :validation="$v.model.scope" :options="$constants.assistant.scopes" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { email, requiredIf } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/assistant/access', 'read'),
			emailMessages: {
				info: 'If the intended user already has a Cardboard Events account then you should use that account\'s e-mail address to link the login with their profile.',
				added: 'This e-mail address is associated with a Cardboard Events account that is already has access.',
				exists: 'This e-mail address is already associated with an existing Cardboard Events account. They will be granted access.',
				register: 'A Cardboard Events login will be generated for this e-mail address and will be given access. The login credentials will automatically be e-mailed to the user.'
			},
			model: {
				id: false,
				exists: false,
				used: false,
				email: '',
				name: '',
				firstname: '',
				lastname: '',
				status: 1,
				type: 1,
				scope: []
			}
		}

	},

	validations: {
		model: {
			email: {
				required: requiredIf('isAccount'),
				email
			}
		}
	},

	computed: {

		isAccount: function() {
	
			return this.model.type === this.$constants.assistant.login.type.account

		}

	},

	methods: {

		onExistsChange: function(e) {

			this.model.exists = e.exists
			this.model.used = e.used
			this.model.firstname = e.firstname || this.model.firstname
			this.model.lastname = e.lastname || this.model.lastname

		}

	}

}

</script>

<style scoped>

</style>